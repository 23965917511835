<template>
    <div class="winner-page__main-content ">
        <div class="email-info-message-background--padding">
            <div class="expired-container-wrap">

                <!-- Már regisztrált -->
                <div
                    class="expired-container second-background-inner--winner-messsage text-center form--status__content--expired block--big"
                    v-if="alreadyRegistered"
                >
                    <div class="container-xl">
                        <div class="row">
                            <div class="col-12 col-lg-10 offset-lg-1 py-5">
                                <div class="expired-container__inner py-5">
                                    <img src="@/assets/imgs/ic-success-application.png" class="img-fluid mb-3" style="width:70px"/> 
                                    <div class="main-h1 display-6 text-primary fw-900">
                                        Ehhez a szállításhoz már megadta az adatait és kiválasztotta az időpontot!
                                    </div>
                                    <div class="codefilling-text pt-4">
                                    <!--<div class="text-dark font-secondary">
                                    Mit kell tenned ezután?            
                                    </div>-->
                                        <p class="text-black">A játék ideje alatt növelje nyerési esélyét és a töltsön fel újabb kódokat!</p>
                                    </div>
                                    <div class="btn-wrap m-md-1">
                                        <button class="btn btn-primary mb-sm-5 mt-4 me-sm-4 btn-primary--large" @click="backToForm">
                                        Új kódot töltök fel
                                        </button>
                                <!-- <button class="btn btn-primary mb-5 mt-4 btn-primary--large" @click="backToSendings">
                                    Beküldéseim
                                    </button>
                                </div>-->
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Sikeres adatfeltöltés -->
                    <div id="success-data-upload"
                        class="expired-container second-background-inner  text-center form--status__content--expired block--big"
                        v-if="!uploadedError && !alreadyRegistered && !formExpired && !formDisabled"
                    >
                    <div class="container-xl">
                        <div class="row">
                        <div class="col-12 py-5">
                            <div class="expired-container__inner py-5">
                                <img src="@/assets/imgs/ic-success-application.png" class="img-fluid mb-3" style="width:70px"/> 
                            <div class="main-h1 display-6 text-primary fw-900">Köszönjük, adatait sikeresen beküldte!</div>
                            <!--<div class="codefilling-text">
                                <div class="text-dark font-secondary display-3 py-3 fw-bold">
                            
                                </div>
                            </div>-->
                            <!--<div class="text-container font-secondary fw-normal text-black py-1">
                                <p class="text-container font-secondary">
                                    Növeld az esélyed a Fődíj megnyerésére és tölts fel újabb kódokat <b>2024. július 17-ig!</b><br>
                                    Ne feledd, ha minden játékhéten küldesz be pályázatot, megnyerheted Extra nyereményünket is!
                                </p>
                            </div>-->
                            <!-- <div class="text-container winner-form-divider--custom"></div>
                            <div class="btn-wrap m-md-1">
                                <button class="btn btn-primary mb-sm-5 btn-primary--large mt-4 me-sm-4" @click="backToForm">
                                Új beküldés
                                </button>
                                <button class="btn btn-primary mb-5 mt-4 btn-primary--large" @click="backToSendings">
                                Beküldéseim
                                </button>
                            </div> -->
                            </div>
                        </div>
                        </div>
                    </div>
                </div>

                <!-- lejárt az idő -->        
                <div
                    class="expired-container second-background-inner  text-center form--status__content--expired block--big"
                    v-if="formExpired"
                >
                    <div class="container-xl">
                        <div class="row">
                            <div class="col-12 py-5">
                                <div class="expired-container__inner py-5">
                                    <!-- <img src="@/assets/imgs/ic-success-application.png" class="img-fluid mb-3"/>  -->
                                    <div class="main-h1 display-6 text-primary fw-900">
                                        A szállítási nap kiválasztásának határideje lejárt!
                                    </div>                 
                                    <div class="font-secondary pt-3 text-container">
                                        <p class="form-expired-bold-text font-secondary fw-bold my-3 text-black">Kérjük, figyelje továbbra is levelezőrendszerét, mivel hamarosan egy újabb kiszállítási dátummal fogjuk e-mailben keresni!</p>
                                        <p class="form-expired-bold-text font-secondary fw-bold my-3 text-black">A játék ideje alatt növelje nyerési esélyét és a töltsön fel újabb kódokat!</p>
                                    </div>
                                    <div class="btn-wrap m-md-1">
                                        <button class="btn btn-primary mt-3 btn-primary--large me-sm-4" @click="backToForm">
                                        Új kódot töltök fel
                                        </button>
                                <!-- <button class="btn btn-primary mt-3 btn-primary--large" @click="backToSendings">
                                    Beküldéseim
                                    </button>
                                </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                    <!-- DISABLED FORM -->
                    <div id="success-data-upload"
                        class="expired-container second-background-inner  text-center form--status__content--expired block--big"
                        v-if="formDisabled"
                    >
                    <div class="container-xl">
                        <div class="row">
                        <div class="col-12 py-5">
                            <div class="expired-container__inner py-5">
                                <!-- <img src="@/assets/imgs/ic-success-application.png" class="img-fluid mb-3"/>  -->
                            <div class="main-h1 display-6 text-primary fw-900">Nyereménye visszavonásra került!</div>
                            <!--<div class="codefilling-text">
                                <div class="text-dark font-secondary display-3 py-3 fw-bold">
                            
                                </div>
                            </div>-->
                            <div class="text-container font-secondary fw-normal text-black py-1">
                                <p class="text-container font-secondary">
                                    Kérjük, lépjen kapcsolatba velünk amennyiben kérdése van.
                                </p>
                            </div>
                            <!-- <div class="text-container winner-form-divider--custom"></div>
                            <div class="btn-wrap m-md-1">
                                <button class="btn btn-primary mb-sm-5 btn-primary--large mt-4 me-sm-4" @click="backToForm">
                                Új beküldés
                                </button>
                                <button class="btn btn-primary mb-5 mt-4 btn-primary--large" @click="backToSendings">
                                Beküldéseim
                                </button>
                            </div> -->
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>

export default {
  name: "FormExpiredAndRegistered",
  props: ['formExpired','alreadyRegistered', 'uploadedWinner', 'uploadedError', 'winnerType', 'formDisabled'],
    data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
    }
  },
  methods: {
    backToForm() {
      this.$router.push("/#palyazat");
      window.scrollTo(0, 0);
    },
    backToSendings(){
      this.$router.push("/#bekuldeseim");
      window.scrollTo(0, 0);
    }
  }  
};
</script>
