<template>
    <div class="winner-page-main delivery-form-container" id="winnerFormWrap">    
        <DeliveryFormAfter
            v-if="formExpired || alreadyRegistered || uploadedWinner === true || formDisabled"
            :formExpired="formExpired"
            :alreadyRegistered="alreadyRegistered"
            :formDisabled="formDisabled"
            :winnerType="winnerType"
        />

        <div v-else class="winner-page__main-content">
      
            <div
                class="winner-page__codefilling-form-background second-background-inner block--big winner-page__codefilling-form-background--delivery"
                :class="{ 'congrat-background-space': uploadedWinner === true }">
                <img src="@/assets/imgs/yeti.png" class="img-fluid yeti"/>
                 <img src="@/assets/imgs/hero-tunderke.png" class="img-fluid fairy"/>
                <h2 class="text-center fw-900 text-white py-5 mt-5 px-2">Szállítási adatok megadása</h2>        
            
                <div class="container-xl">
                    <div class="row">
                        <div class="col-md-12 col-lg-10 offset-lg-1">              
                            <div class="codefilling-form" style="border-radius:20px">                
                                <validation-observer
                                    v-if="formStatus === false"
                                    v-slot="{ handleSubmit }"
                                    tag="div"
                                    ref="winnerFormObserver">
                                    <form
                                        class="form form--codefilling"
                                        @submit.prevent="handleSubmit(formSubmit)"
                                        id="winnerForm"
                                    >
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="winner-codefilling__form">             
                                                    <div class="form-group row">
                                                        <div class="col-12 col-lg-6">
                                                            <div class="surname-row input--info col-12">                               
                                                                <CustomInput
                                                                    label="Vezetéknév"
                                                                    name="last_name"                                                                        
                                                                    v-model="form.last_name"
                                                                    disabled
                                                                />  
                                                            </div>
                                                            
                                                            <div class="forname-row input--info col-12">                      
                                                                <CustomInput
                                                                    label="Keresztnév"
                                                                    name="last_name"                                                                        
                                                                    v-model="form.first_name"
                                                                    disabled
                                                                />  
                                                            </div>
                                                            <div class="form-row col-12">
                                                                <CustomInput
                                                                    label="E-mail cím"
                                                                    name="email"
                                                                    v-model="form.email"
                                                                    disabled                     
                                                                />
                                                            </div>
                                                
                                                            <div class="form-group mb-0 form-row col-12">
                                                    
                                                                <div class="form-row phone-wrap phone-row">
                                                                    <div class="form-label">Telefonszám<span class="label-red"> * </span></div>
                                                                    <div class="d-flex w-100 align-items-start position-relative">
                                                                        <span class="me-2 d-block phone-prefix">+36</span>
                                                                        <CustomInput
                                                                            class="w-100"
                                                                            label=""
                                                                            name="phone"                                                                                        
                                                                            tooltip="Egy telefonszám, amelyen a játékos elérhető."
                                                                            v-model="phoneShort"                                        
                                                                            @input="formatPhoneNumber($event)"                                                                                
                                                                            rules="required|customPhone"
                                                                            :disabled="is_phone_number_already"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-lg-6 text-center">
                                                            <div class="p-5 text-black">
                                                                <img src="@/assets/imgs/delivery-daily.png" v-if="winnerType == 'daily'" class="img-fluid d-block mx-auto pt-3">
                                                                <img src="@/assets/imgs/delivery-hatizsak.png" v-else-if="winnerType == 'weekly_hatizsak'" class="img-fluid d-block mx-auto pt-3">
                                                                <img src="@/assets/imgs/delivery-borond.png" v-else-if="winnerType == 'weekly_borond'" class="img-fluid d-block mx-auto pt-3">
                                                                <img src="@/assets/imgs/delivery-monthly.png" v-else-if="winnerType == 'monthly'" class="img-fluid d-block mx-auto pt-3">
                                                                <img src="@/assets/imgs/delivery-main.png" v-else class="img-fluid d-block mx-auto pt-3">
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                    <div class="form-group row">
                                                        <div class=" form-group zip-row input--info col-12 col-md-6">
                                                            <!-- zip  -->                                 
                                                            <div class="col-12">
                                                            <CustomInput
                                                                label="Irányítószám"
                                                                type="text"
                                                                name="zip"
                                                                placeholder="Irányítószám"                                          
                                                                v-model="form.zip"
                                                                rules="required|min:4"
                                                                :disabled="is_step2_done"
                                                                v-mask="['####']"
                                                            />
                                                            </div>
                                                        </div>
                                                        <div class="form-group input--info city-row col-12 col-md-6">                                 
                                                            <div class="col-12">
                                                                <CustomInput
                                                                    label="Település"
                                                                    type="text"
                                                                    name="city"
                                                                    placeholder="Település"                                          
                                                                    v-model="form.city"
                                                                    rules="required"
                                                                    :disabled="is_step2_done"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div class="input--info street-row col-12 col-md-6">
                                                            <CustomInput
                                                                label="Utca"
                                                                type="text"
                                                                name="street"
                                                                placeholder="Utca"                                          
                                                                v-model="form.street"
                                                                rules="required"
                                                                :disabled="is_step2_done"
                                                            />
                                                        </div>
                                                        <div class="input--info house-row col-12 col-md-6">
                                                            <CustomInput
                                                                    label="Házszám, emelet, ajtó"
                                                                    type="text"
                                                                    name="house"
                                                                    placeholder="Házszám, emelet, ajtó"                                          
                                                                    v-model="form.house"
                                                                    rules="required"
                                                                    :disabled="is_step2_done"
                                                                />
                                                        </div>                                                                                 
                                                    </div>
                                                    <div class="form-group row">
                                                        <div class="col-12 col-lg-6 mb-5">
                                                            <DropDown
                                                                label="Nyeremény szállítási ideje"
                                                                name="delivery_times"
                                                                placeholder="Válassz"
                                                                :options="deliveryTimes"
                                                                @selectedItem="form.delivery_times = $event"
                                                                rules="required"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <div class="col-12 col-lg-6">
                                                            <CustomText
                                                                label="Szállítási cím, ha eltér a lakcímtől"
                                                                name="other_address"
                                                                v-model="form.other_address"
                                                            />
                                                        </div>
                                                        <div class="col-12 col-lg-6">
                                                            <CustomText
                                                                label="Üzenet a futárnak"
                                                                name="delivery_message"
                                                                v-model="form.delivery_message"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div style="width:100%;border-top:1px solid rgba(87, 182, 215, .3)" class="my-5"></div>
                                            
                                                    <div class="mt-3 mb-4 text-center d-flex">                                
                                                        <span class="text-red fw-bold">*</span>
                                                        <span>&nbsp;</span> 
                                                        <span><p class="m-0 font-secondary" style="font-size:14px">A csillaggal jelölt mezők kitöltése szükséges.</p></span>
                                                    </div>
                                                    <!-- info text -->
                                                    <div class="info-block d-flex align-items-center p-4 mb-7" style="border-radius:8px;background:#FFF8BC;border: 2px solid #FFE401;">                      
                                                        <img src="@/assets/imgs/exclamation-circle.svg" alt="mark" class="img-fluid me-2" /> 
                                                        <p class="m-0 text-black fs-14">Kérjük, olyan címet adjon meg, ahol át tudja venni a nyereményt! A vásárlást igazoló eredeti blokkot 2025. január 24-ig őrizze meg!</p>
                                                    </div>

                                                    <!-- SUBMIT button -->
                                                    <div class="d-flex justify-content-center mt-4">
                                                            <button type="submit" class="btn btn-primary mr-0 btn-primary--large">
                                                                <span>Adatok elküldése</span>      
                                                            </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                    
                                    </form>
                                </validation-observer>                
                            </div>
                        </div>
                    </div>
                </div>        
            </div>      
        </div>    
    </div>
</template>

<script>
import {mask} from "vue-the-mask";
// import $ from "jquery";
/* eslint-disable */
//import CodeFillingWinnerformHero from '@/components/CodeFillingWinnerFormHero.vue'
import {ValidationProvider, ValidationObserver, extend} from "vee-validate";
import {required, email} from "vee-validate/dist/rules";
import {setInteractionMode} from "vee-validate";
import DropDown from '@/components/base/DropDown.vue'
import DeliveryFormAfter from "@/components/DeliveryFormAfter";
import CustomInput from '@/components/base/CustomInput.vue'
import CustomText from '@/components/base/CustomText.vue'
import axios from 'axios'

setInteractionMode("eager");
// Add a validation rules

extend("email", {
  ...email  
});
extend("required", {
  ...required  
});
extend("min", {
  validate(value, args) {
    return value.length >= args.length;
  },
  params: ["length"]  
});


export default {
  data() {
    return {
      form: {
        last_name: "",
        first_name: "",
        email: "",
        zip: "",
        city: "",
        phone: "",
        street: "",
        house: "",
        delivery_times: "",
        delivery_message: "",
        other_address: "",
        hash: this.$route.query.hash     
      },
      deliveryTimes: null,
      phoneShort: '',
      phoneRaw:'',
      formStatus: false,
      alreadyRegistered: false,
      responseErrors: null,
      triedFillForm: false,
      uploadedWinner: false,
      winnerType: null,
      formExpired: false,
      formDisabled: false,
      formIsSubmitting: false,
      is_phone_number_already:false,
      is_step2_done: false
    };
  },
  components: {
    DeliveryFormAfter,
    ValidationProvider,
    ValidationObserver,
    CustomInput,
    CustomText,
    DropDown
  },
  directives: {
    mask,
  },

  methods: {
    backToForm() {
      this.$router.push("/");
      window.scrollTo(0, 0);
    }, 
    getFormData(){
        let _this = this
        let formData = new FormData();
                     
      //  _this.form.phone = '+36-' + this.phoneShort        

       // formData.append("phone", _this.form.phone);
       // formData.append("zip", _this.form.zip);
       // formData.append("city", _this.form.city);
       // formData.append("street", _this.form.street);
       // formData.append("house", _this.form.house);
        formData.append("hash", _this.form.hash);
        formData.append("delivery_message", _this.form.delivery_message);  
        formData.append("delivery_time", _this.form.delivery_times);  
        formData.append("delivery_address", _this.form.other_address);    

        return formData
    },
    formSubmit() {
      var _this = this;      

      if (!_this.formIsSubmitting) {
        _this.formIsSubmitting = true      

        let formContent = null
        formContent = _this.getFormData()

        axios.post(process.env.VUE_APP_API_URL +"step3/upload", formContent, {})
            .then((response) => {
              var _this = this;              
              console.log(_this.response);
              if (!response.data.status || response.data.status === false) {                                
                console.log("response false")
                _this.goToError(error) 

              } else if (response.data.status) {
                console.log('success post')
                //success
                _this.formStatus = true;
                _this.uploadedWinner = true;
                // scroll to success screen's top
              
                setTimeout(() => {
                  let element = document.querySelector('#success-data-upload')
                  if (element){
                      _this.scrollToElement(element, 20)       
                  }
                }, 300);
                  
              }

              _this.formIsSubmitting = false
              
            }).catch(function (error) {
              console.log('catch post')
              console.log(error);
              _this.formIsSubmitting = false

              let errorsArray = Object.entries(error.response.data.error)
             // for (const [errorName, errorValue] of errorsArray) {
                 
                        
            // GTMcategory = 'egyeb_hiba'                                                        
                  
            //  }

              _this.goToError(error)                        

            });
      } else {
            console.log('double form submit')
      }


    }, 
    goToError(err){            
        let _this = this
        //scroll to the first error
        this.setErrorMsgs(err, 'winnerFormObserver').then(()=>{                               
          let firstError = document.querySelector('#winnerForm .error')
          if (firstError){
              _this.scrollToElement(firstError, 20)       
          }          
        })                                       
    },
  },  
  created() {    
    let _this = this

    // for safety reasons - logged out the user   
    //TODO: csak akkor léptessük ki ha nem egyezik meg a user  
    localStorage.setItem("token", false);    

    axios.get(
            process.env.VUE_APP_API_URL +
            "index.php/step3/hash?hash=" +
            this.$route.query.hash
        )
        .then((response) => {
          //console.log(response.data)

          if (response.data.status === false) {
            //console.log('status false')
            //not winner or not good hash
            console.log('error')
            this.$router.push({name: "Home"});
          } else if (response.data.status.toLowerCase() == "ok") {
            
            //a winner opened the page
            if (!response.data.winner) {
              // console.log("nem nyertes")
              this.$router.push({name: "Home"});
            } else {
              
              if (parseInt(response.data.winner.step2_done) === 1 ) {
            
             //   _this.formStatus = true;
              //  _this.alreadyRegistered = true;
               _this.is_step2_done = true;
              }

              if(parseInt(response.data.winner.delivery_form_done) === 1 ) {
                _this.alreadyRegistered = true;
                _this.formStatus = true;
              }
              
              _this.winnerType = response.data.winner.type;
              _this.form.email = response.data.winner.email;
              _this.form.first_name = response.data.winner.surname;
              _this.form.last_name = response.data.winner.forename;
              //user data
              _this.form.street = response.data.winner.street
              _this.form.house = response.data.winner.house
            if (response.data.winner.phone){
                  _this.is_phone_number_already = true //for disabled the phone number
                  _this.form.phone = response.data.winner.phone.slice(4)
                  _this.phoneShort = response.data.winner.phone.slice(4)
            }

            if(response.data.winner.offered_delivery_times){
                _this.deliveryTimes = JSON.parse(response.data.winner.offered_delivery_times);
                console.log(_this.deliveryTimes);
            }
              
              _this.form.city = response.data.winner.city
              _this.form.zip = response.data.winner.zip
            }
          } else if (response.data.status.toLowerCase() == "expired"){            
            //the time is over than 5 days
            if(parseInt(response.data.winner.delivery_form_done) === 1 ) {              
                _this.alreadyRegistered = false;
                _this.formStatus = false;
            } else {
              this.formExpired = false;
              _this.winnerType = response.data.winner.type;
            }
            
          } else if (response.data.status.toLowerCase() == "disabled") {            
            // console.log("status disabled");
            this.formDisabled = true;
            _this.winnerType = response.data.winner.type;
          }
        }).catch(function(){       
          //the url isn't valid  
          _this.$router.push({name: "Home"});
        })
  } 
};
</script>
